<!--
 * @Author: yuwenwen
 * @Date: 2022-11-23 14:53:50
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-25 11:04:48
 * @FilePath: \nc-funeral-mobile\src\views\errorpage\isBuilding.vue
-->
<template>
    <div class="page">
       <img src="../../assets/images/is-building.png" />
       <div class="text">功能开发中....</div>
    </div>
</template>
<script>
export default {
    name:'IsBuilding',
    data(){
        return {

        }
    },
    created(){

    },
    methods:{

    }
}
</script>
<style lang="less" scoped>
  .page{
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
        width: 90px;
        height: 90px;
    }
    .text{
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
    }
  }
</style>